<template>
  <div>
    <template v-if="showDeliveryTime">
      <div class="font-grey-dark-1">
        <!-- <span v-if="isDelivery">Scheduled for </span> -->
        <span>Pick up at </span>
        {{ setTimeZone(deliveryTime, 'ddd, D MMM, hh:mm A') }}
      </div>
      <v-divider class="my-3"></v-divider>
    </template>
    <div>
      <template v-for="(set, setIndex) in combinedCartItems">
        <div :key="setIndex" :class="{ 'mb-2': combinedCartItems.length > 1 }">
          <div v-if="isUndefinedNullOrEmpty(set.items)" class="font-1r font-weight-medium">
            {{ `${set.label} x ${set.qty}` }}
          </div>
          <div v-else class="font-1r font-weight-medium mb-1">
            {{ `${set.label} x ${set.qty}` }}
          </div>
          <template v-for="(item, index) in set.items">
            <ul :key="index">
              <li class="font-1r">{{ item.name }}</li>
              <!-- <li class="font-1dot1r">{{ `${item.name} x ${item.qty}` }}</li> -->
            </ul>
          </template>
        </div>
      </template>
    </div>

    <template v-if="isDelivery">
      <v-divider class="my-3"></v-divider>

      <div class="d-flex justify-space-between align-center mb-2">
        <div class="font-weight-medium font-1dot1r">{{ $t('orders.lbl.subtotal') }}</div>
        <div class="font-weight-medium font-1dot1r">{{ `${$t('lbl.rm')}${priceFormat(amtInfo.subtotal)}` }}</div>
      </div>
      <div class="d-flex justify-space-between align-center mb-2">
        <div class="font-weight-medium font-1dot1r">{{ $t('orders.lbl.deliveryFee') }}</div>
        <div class="font-weight-medium">
          <span v-if="amtInfo.discount" class="line-through mr-2" style="color:var(--grey-dark-1)">{{
            `${$t('lbl.rm')}${priceFormat(amtInfo.deliveryFeeBeforeDisc)}`
          }}</span>
          <span class="font-1dot1r">{{ `${$t('lbl.rm')}${priceFormat(amtInfo.deliveryFeeAfterDisc)}` }}</span>
        </div>
      </div>
    </template>

    <template v-if="showOfferSection">
      <template v-if="!isDelivery">
        <v-divider class="my-3"></v-divider>

        <div class="d-flex justify-space-between align-center mb-2">
          <div class="font-weight-medium font-1dot1r">{{ $t('orders.lbl.subtotal') }}</div>
          <div class="font-weight-medium font-1dot1r">{{ `${$t('lbl.rm')}${priceFormat(amtInfo.amtBeforePromo)}` }}</div>
        </div>
      </template>

      <div v-if="offerObj.discountAmt > 0" class="d-flex justify-space-between align-center mb-2">
        <div class="font-weight-medium font-1dot1r">{{ 'Discount' }}</div>
        <div class="font-weight-medium font-1dot1r">{{ `- ${$t('lbl.rm')}${priceFormat(offerObj.discountAmt)}` }}</div>
      </div>
    </template>

    <v-divider class="my-2"></v-divider>
    <div class="d-flex justify-space-between align-center">
      <div class="font-weight-medium font-1dot1r">{{ $t('lbl.total') }}</div>
      <div class="font-weight-medium font-red-common font-1dot2r">{{ `${$t('lbl.rm')}${priceFormat(amtInfo.totalAmt)}` }}</div>
    </div>
  </div>
</template>
<script>
import { DELIVERY_CHANNEL } from '@/common/enum/checkout';
export default {
  props: {
    cartItems: {
      type: Array,
      default: null,
    },
    focCartItems: {
      type: Array,
      default: null,
    },
    amtInfo: {
      type: Object,
      default: null,
    },
    isDelivery: {
      type: Boolean,
      default: false,
    },
    promoObj: {
      type: Object,
      default: null,
    },
    offerObj: {
      type: Object,
      default: null,
    },
    orderType: {
      type: Number,
      default: null,
    },
    deliveryTime: {
      type: String,
      default: null,
    },
  },
  computed: {
    combinedCartItems() {
      if (!this.isUndefinedNullOrEmpty(this.focCartItems)) {
        return this.cartItems.concat(this.focCartItems);
      }
      return this.cartItems;
    },
    showOfferSection() {
      return this.offerObj?.discountAmt > 0;
    },
    showDeliveryTime() {
      // return [DELIVERY_CHANNEL.PICK_UP, DELIVERY_CHANNEL.DELIVERY].indexOf(this.orderType) != -1;
      return [DELIVERY_CHANNEL.PICK_UP].indexOf(this.orderType) != -1;
    },
  },
};
</script>
