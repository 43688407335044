<template>
  <div>
    <ValidationObserver ref="observer">
      <div class="mb-3">
        <div class="note">You are buying from store:</div>

        <div class="mb-2">
          <Select
            v-model="form.storeId"
            :rules="rules.storeId"
            :options="storeDdlListWithPrefix"
            :outlined="false"
            :hide-details="false"
            :single-line="true"
            :label="$t('store.lbl.selectStore')"
            :placeholder="$t('store.lbl.selectStore')"
            :clearable="false"
            :read-only="validStoreFound"
            @change="storeChg"
          />
        </div>
      </div>
      <div>
        <div class="note mb-2">Fill in your details</div>

        <div class="font-13 mb-2">
          <i>*We will use this name to address you</i>
        </div>
        <div>
          <TextField
            ref="name"
            v-model="form.name"
            :rules="rules.name"
            :outlined="false"
            :hide-details="false"
            :single-line="false"
            :label="$t('profile.lbl.yourName')"
          />
        </div>
        <div>
          <TextField
            ref="mobile"
            v-model="form.mobile"
            :rules="rules.mobile"
            :outlined="false"
            :hide-details="false"
            :single-line="false"
            :label="$t('profile.lbl.phoneNo')"
            :type="'number'"
          />
        </div>
        <div>
          <TextField
            v-model="form.notes"
            :rules="rules.notes"
            :outlined="false"
            :hide-details="false"
            :single-line="false"
            :label="$t('lbl.remarks')"
            :max-length="rules.notes.max"
            :counter="rules.notes.max"
          />
        </div>
      </div>

      <!-- <div class="sign-up-wrap">
        <div class="mb-2"><a href="#">Sign up</a> to enjoy more benefits</div>
        <div>Already have an account? <a href="#">Sign in</a></div>
      </div> -->

      <!-- <v-divider class="my-6"></v-divider> -->

      <!-- <v-divider class="mt-4 mb-3"></v-divider> -->

      <!-- <PromoCode /> -->

      <v-divider class="mt-4 mb-3"></v-divider>

      <v-expansion-panels v-model="orderSummaryPanel" class="order-summ mb-2" accordion>
        <v-expansion-panel>
          <v-expansion-panel-header class="px-3 py-2">
            <div class="d-flex justify-space-between align-center">
              <div class="font-weight-medium font-1dot1r">Order Summary</div>
              <div v-if="orderSummaryPanel == null" class="font-weight-medium font-red-common font-1dot2r">
                <span v-if="amtInfo.amtBeforePromo != totalAmt" class="line-through font-1dot1r font-grey-dark-1 mr-2">{{
                  `${$t('lbl.rm')}${priceFormat(amtInfo.amtBeforePromo)}`
                }}</span>
                <span class="font-red-common font-1dot1r">{{ `${$t('lbl.rm')}${priceFormat(totalAmt)}` }}</span>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="mb-3"></v-divider>
            <Summary
              :cart-items="currentCartItems"
              :foc-cart-items="currentFocCartItems"
              :amt-info="amtInfo"
              :offer-obj="offerObj"
              :order-type="DELIVERY_CHANNEL.TAKEAWAY"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-divider class="mt-4 mb-3"></v-divider>

      <div class="note mb-3">{{ $t('payment.lbl.paymentMethod') }}</div>

      <ValidationProvider v-slot="{ errors }" :name="$t('payment.lbl.paymentMethod')" :rules="rules.payOption">
        <v-radio-group v-model="payOption" class="pay-option-wrap" row :error-messages="errors[0]" @change="payOptionChg">
          <v-radio :label="$t('pymt.act.payAtCounter')" :value="PAY_OPTION.PAY_AT_COUNTER"> </v-radio>
          <v-radio :label="$t('pymt.act.payOnline')" :value="PAY_OPTION.PAY_ONLINE"> </v-radio>
          <template v-if="payOption == PAY_OPTION.PAY_ONLINE">
            <div class="w-100 mt-1">
              <TextField
                ref="email"
                v-model="form.email"
                :rules="rules.email"
                :outlined="false"
                :hide-details="false"
                :single-line="false"
                :label="$t('profile.lbl.email')"
                :type="'email'"
              />
            </div>
            <ValidationProvider v-slot="{ errors }" :name="$t('payment.lbl.paymentChannel')" :rules="rules.payChannel">
              <PaymentChannel v-model="form.payMethod" :list="onlinePayMethodList" />
              <span v-if="!isUndefinedNullOrEmpty(errors)" class="error-msg">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
        </v-radio-group>
      </ValidationProvider>

      <PaymentForm ref="paymentForm" :data="pgFormData" />

      <AppBtn class="btn-black" block :height="42" :loading="loading" @click="placeOrder">{{ 'Place Order' }}</AppBtn>

      <CommonModal
        v-model="quotationModalShow"
        :modal-size="'modal-sm'"
        :title="'Discount Info Updated'"
        :footer="false"
        :close-btn="true"
      >
        <template #body>
          <div class="pa-3">
            <div class="font-1dot1r font-weight-regular mt-2 mb-4">
              Discount info have been updated.
            </div>
            <div class="text-right">
              <AppBtn class="btn-orange" @click="quotationModalShow = false">{{ $t('act.ok') }}</AppBtn>
            </div>
          </div>
        </template>
      </CommonModal>

      <CommonModal
        v-model="notAvailableModalShow"
        :modal-size="'modal-sm'"
        :title="'Takeaway Not Available'"
        :footer="false"
        :close-btn="true"
      >
        <template #body>
          <div class="pa-3">
            <div class="font-1dot1r font-weight-regular mt-2 mb-4">
              Sorry, we are unable to serve your order temporarily. Please try again later.
            </div>
            <div class="text-right">
              <AppBtn
                class="btn-orange"
                @click="
                  notAvailableModalShow = false;
                  $emit('back');
                "
                >{{ $t('act.ok') }}</AppBtn
              >
            </div>
          </div>
        </template>
      </CommonModal>
    </ValidationObserver>
  </div>
</template>
<script>
import PaymentChannel from '../../payment/channel';
import PaymentForm from '../../payment/form';
import CommonModal from '@/components/Modal';
// import PromoCode from '@/views/Promo/Code';
import Summary from '@/views/Order/summary';
import { DELIVERY_CHANNEL, PAY_OPTION } from '@/common/enum/checkout';
import { orderApi } from '@/api/order';
import { quotationApi } from '@/api/offer';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
// import moment from 'moment';

export default {
  components: {
    CommonModal,
    PaymentChannel,
    PaymentForm,
    // PromoCode,
    Summary,
  },
  data() {
    return {
      form: {
        mobile: '',
        name: '',
        email: '',
        storeId: null,
        payMethod: null,
        notes: '',
        quotationSignature: null,
      },
      quotation: {},
      loading: false,

      pgFormData: {
        actionUrl: '',
        merchantId: '',
        amount: '',
        orderId: '',
        billName: '',
        billEmail: '',
        billMobile: '',
        billDesc: '',
        country: '',
        vcode: '',
        currency: '',
        channel: '',
        returnurl: '',
        callbackurl: '',
      },

      payOption: null,

      storeDdlListWithPrefix: [],
      validStoreFound: false,
      storeName: '',
      orderSummaryPanel: null,

      quotationModalShow: false,
      notAvailableModalShow: false,

      rules: {
        mobile: { required: true, min: 9, regex: /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/ },
        name: { required: true, min: 2 },
        storeId: { required: true },
        email: { required: true, email: true },
        payOption: { required: true },
        payChannel: { required: true },
        notes: { required: false, max: 50 },
      },
    };
  },
  computed: {
    ...mapState({
      storeDdlList: state => state.store.storeDdlList,
      onlinePayMethodList: state => state.lookup.onlinePayMethodList,
      selectedPromo: state => state.promo.selectedPromo,
    }),
    ...mapGetters([
      'getCartItems',
      'getFocCartItems',
      'getPlacedCartItems',
      'getPlacedFocCartItems',
      'getUserDisplayName',
      'getMobileNo',
      'getEmail',
      'getSelectedPromo',
      'getSelectedPromoInfo',
      'getPromoList',
    ]),
    PAY_OPTION() {
      return PAY_OPTION;
    },
    DELIVERY_CHANNEL() {
      return DELIVERY_CHANNEL;
    },
    currentCartItems() {
      return !this.isUndefinedNullOrEmpty(this.getPlacedCartItems) ? this.getPlacedCartItems : this.getCartItems;
    },
    currentFocCartItems() {
      return !this.isUndefinedNullOrEmpty(this.getPlacedFocCartItems) ? this.getPlacedFocCartItems : this.getFocCartItems;
    },
    totalAmtBeforePromo() {
      if (this.isUndefinedNullOrEmpty(this.currentCartItems)) return 0;
      let total = 0;
      for (let item of this.currentCartItems) {
        total = total + item.qty * item.price;
      }
      return total;
    },
    totalAmt() {
      if (!this.isUndefinedNullOrEmpty(this.offerObj)) return this.totalAmtBeforePromo - this.offerObj.discountAmt;
      return this.totalAmtBeforePromo;
    },
    amtInfo() {
      return {
        totalAmt: this.totalAmt,
        amtBeforePromo: this.totalAmtBeforePromo,
      };
    },
    offerObj() {
      if (this.isUndefinedNullOrEmpty(this.quotation)) return {};
      return {
        discountedAmt: this.quotation.amt,
        discountRate: this.quotation.rate.disc, // 0.2 . Not in percentage.
        discountAmt: this.quotation.rate.discAmt,
      };
    },
    // promoObj() {
    //   if (this.isUndefinedNullOrEmpty(this.getPromoList)) return {};
    //   return this.getPromoList[this.selectedPromo];
    // },
  },
  async created() {
    this.getTakeawayQuotation();

    if (this.isUndefinedNullOrEmpty(this.onlinePayMethodList)) this.fetchOnlinePayMethodList();

    if (this.isUndefinedNullOrEmpty(this.storeDdlList)) {
      await this.fetchStoreDdl();
    }

    this.storeDdlListWithPrefix = this.storeDdlList.map(store => {
      return {
        ...store,
        text: `${this.$t('app.org.storePrefix')} ${store.text}`,
      };
    });

    if (!this.isUndefinedNullOrEmpty(this.$route.query.storeId)) {
      const storeId = Number(this.$route.query.storeId);

      // To check whether the storeId from query string is valid
      const validStore = this.storeDdlList.find(store => store.value == storeId);
      if (!this.isUndefinedNullOrEmpty(validStore)) {
        this.form.storeId = validStore.value;
        this.storeName = validStore.text;
        this.validStoreFound = true;
      }
    }

    if (!this.isUndefinedNullOrEmpty(this.getUserDisplayName)) this.form.name = this.getUserDisplayName;
    if (!this.isUndefinedNullOrEmpty(this.getMobileNo)) this.form.mobile = this.getMobileNo;
    if (!this.isUndefinedNullOrEmpty(this.getEmail)) this.form.email = this.getEmail;
  },
  methods: {
    ...mapMutations([
      'SET_STORE_ID',
      'SET_STORE_NAME',
      'SET_USER_DISPLAY_NAME',
      'SET_USER_MOBILE_NO',
      'SET_EMAIL',
      'SET_ORDER_USER_ID',
      'SET_MY_REF_TOKEN',
      'SET_GUEST_TOKEN',
    ]),
    ...mapActions([
      'clearCart',
      'setOrderHistory',
      'setOrderPending',
      'fetchStoreDdl',
      'fetchOnlinePayMethodList',
      'setPlacedCart',
      'setPlacedFocCart',
      'processUserLoginSuccess_act',
    ]),
    storeChg(value) {
      const validStore = this.storeDdlList.find(store => store.value == value);
      this.storeName = validStore.text;
    },
    payOptionChg(value) {
      if (value == PAY_OPTION.PAY_ONLINE) {
        setTimeout(() => {
          window.scrollBy(0, 50);
        }, 100);
      }
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    getTakeawayQuotation() {
      return quotationApi.getTakeawayQuotation({ amt: this.totalAmtBeforePromo }).then(res => {
        const data = res.data;
        if (!this.isUndefinedNullOrEmpty(data?.quotation)) {
          this.quotation = data.quotation;
        }
        if (this.isUndefinedNullOrEmpty(data?.signature)) {
          this.notAvailableModalShow = true;
        } else {
          this.form.quotationSignature = data.signature;
        }
        return data;
      });
    },
    async placeOrder() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      if (this.isUndefinedNullOrEmpty(this.currentCartItems)) {
        this.showError('Your cart is empty');
        return;
      }

      if (this.isUndefinedNullOrEmpty(this.form.quotationSignature)) {
        this.showError('Takeaway not available at the moment, please try again later');
        return;
      }

      let data = {
        storeId: this.form.storeId,
        channel: DELIVERY_CHANNEL.TAKEAWAY,
        amt: Number(this.priceFormat(this.totalAmt)),
        discAmt: this.offerObj?.discountAmt,
        mobile: this.form.mobile.trim(),
        name: this.form.name.trim(),
        items: [...this.processItems(this.currentCartItems), ...this.processItems(this.currentFocCartItems)],
        promoCode: this.selectedPromo,
        notes: this.form.notes,
        quotationSignature: this.form.quotationSignature,
      };

      if (this.payOption == PAY_OPTION.PAY_ONLINE) {
        data.email = this.form.email.trim();
        data.payMethod = this.form.payMethod;
      }

      this.loading = true;

      return orderApi
        .place(data)
        .then(async res => {
          if (res.ret == '0') {
            const resData = res.data;

            this.SET_STORE_ID(this.form.storeId);
            this.SET_STORE_NAME(this.storeName);
            this.SET_USER_DISPLAY_NAME(data.name);
            this.SET_USER_MOBILE_NO(data.mobile);
            this.SET_EMAIL(data.email);
            this.SET_ORDER_USER_ID(resData.userId);
            // this.SET_MY_REF_TOKEN(resData.referralToken);

            if (!this.isUndefinedNullOrEmpty(resData.userToken)) {
              this.processUserLoginSuccess_act({
                token: resData.userToken,
                userId: resData.userId,
                login: data.mobile,
                name: data.name,
                email: data.email,
              });
            }

            // const orderData = {
            //   amt: resData.amt,
            //   orderId: resData.orderId,
            //   seq: resData.seq,
            //   curSeq: resData.curSeq,
            //   userId: resData.userId,
            //   time: moment.parseZone(new Date()).format('h:mma'),
            //   data: [...this.currentCartItems, ...this.currentFocCartItems],
            //   cancelled: 0,
            //   status: resData.status,
            //   dChannel: DELIVERY_CHANNEL.TAKEAWAY,
            //   storeName: this.storeName,
            //   locName: '',
            // };
            // this.setOrderHistory(orderData);
            // await this.clearCart();
            this.setPlacedCart(this.currentCartItems);
            this.setPlacedFocCart(this.currentFocCartItems);

            if (this.payOption == PAY_OPTION.PAY_ONLINE) {
              // this.setOrderPending(orderData);
              this.postToPg(resData);
            } else {
              this.navigatePush('OrderDetails', { orderId: resData.orderId }, { storeId: this.form.storeId });
            }
          } else {
            if (res.ret == 'InvalidSignature') {
              const quotationRes = await this.getTakeawayQuotation();
              if (quotationRes.signature) {
                this.quotationModalShow = true;
              }
            } else if (res.ret == 'InvalidRequest') {
              if (res.data?.cause[0]?.err) {
                this.showError(res.data.cause[0].err);
              } else {
                this.showError(res.msg);
              }
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    postToPg(data) {
      this.pgFormData.actionUrl = data.tx.url;
      this.pgFormData.merchantId = data.tx.merchantId;
      this.pgFormData.amount = this.priceFormat(data.amt); // Need to pass .00 to Razer . Eg. 1.00, 2.00
      this.pgFormData.orderId = data.tx.orderNo;
      this.pgFormData.billName = this.form.name;
      this.pgFormData.billEmail = this.form.email;
      this.pgFormData.billMobile = this.form.mobile;
      this.pgFormData.country = data.tx.country;
      this.pgFormData.vcode = data.tx.vcode;
      this.pgFormData.currency = data.tx.curr;
      this.pgFormData.channel = data.tx.channel;

      this.$nextTick(() => {
        this.loading = true;
        this.$refs['paymentForm'].postToPg();
        this.loading = false;
      });
    },
    processItems(data) {
      return data.map(item => {
        return {
          itemId: item.menuItemId,
          qty: item.qty,
          items: !this.isUndefinedNullOrEmpty(item.items)
            ? item.items.map(innerItem => {
                return {
                  itemId: innerItem.itemId,
                  qty: innerItem.qty,
                };
              })
            : null,
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.note {
  color: var(--primary-black-color);
  font-size: 1.15rem;
  font-weight: 500;
}
// .pay-option-wrap {
//   .v-radio::v-deep {
//     .v-icon {
//       font-size: 30px;
//     }
//     .v-label {
//       font-size: 1.3rem;
//       font-weight: 400;
//     }
//   }
// }
</style>
