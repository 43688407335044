<template>
  <div>
    <ValidationObserver ref="observer">
      <div>
        <div>
          <!-- <div class="note mt-4 mb-2">
            Delivery Time <span class="font-weight-regular font-1dot25r mb-1">{{ `(${currentDate})` }}</span>
          </div> -->

          <!-- <div class="mb-5">
            <Select
              v-model="form.schedule"
              :rules="rules.schedule"
              :options="scheduleList"
              :outlined="false"
              :single-line="true"
              :label="$t('delivery.lbl.deliveryTime')"
              :placeholder="$t('delivery.lbl.selectTimeSlot')"
              :clearable="false"
              @change="scheduleChg"
            />
          </div> -->

          <div class="note">
            {{ $t('lbl.date') }}
          </div>

          <Select
            v-model="form.date"
            :rules="rules.date"
            :options="dateList"
            :outlined="false"
            :single-line="true"
            :label="$t('lbl.date')"
            :placeholder="$t('pickup.lbl.selectDate')"
            :clearable="false"
            @change="dateChg"
          />

          <div class="note mt-4">
            {{ $t('lbl.time') }}
          </div>

          <Select
            v-model="form.schedule"
            :rules="rules.schedule"
            :options="timeList"
            :outlined="false"
            :single-line="true"
            :label="$t('lbl.time')"
            :placeholder="$t('pickup.lbl.selectTimeSlot')"
            :clearable="false"
            @change="timeChg"
          />
        </div>

        <div v-if="showDeliverySection" class="mt-4 mb-2">
          <div class="note mb-2">Delivery Address</div>
          <div class="font-13 mb-2">
            * Enter postcode to check if it’s within the delivery area
          </div>
          <div>
            <TextField
              v-model="form.postcode"
              :rules="rules.postcode"
              :max-length="postcodeLength"
              :outlined="false"
              :hide-details="false"
              :single-line="false"
              :label="$t('profile.lbl.postcode')"
              :loading="postcodeLoading"
              :read-only="postcodeDisabled"
            />
          </div>

          <template v-if="isDeliveryArea">
            <!-- <div>
              <TextField
                v-model="form.unit"
                :rules="rules.unit"
                :outlined="false"
                :hide-details="false"
                :single-line="false"
                :label="$t('profile.lbl.unitBlockHouse')"
              />
            </div> -->

            <div>
              <TextField
                v-model="form.address"
                :rules="rules.address"
                :outlined="false"
                :hide-details="false"
                :single-line="false"
                :label="$t('profile.lbl.address')"
              />
            </div>

            <!-- <div>
              <TextField
                v-model="form.city"
                :rules="rules.city"
                :outlined="false"
                :hide-details="false"
                :single-line="false"
                :label="$t('profile.lbl.city')"
              />
            </div> -->
          </template>
        </div>

        <template v-if="isDeliveryArea">
          <div class="mt-4 mb-2">
            <div class="note mb-2">Fill in your details</div>

            <div v-if="isReturnCust" class="cust-info-wrap d-flex align-center font-weight-regular pa-2 mb-2">
              <div>{{ form.name }}</div>
              <div>{{ `, ${form.mobile}` }}</div>
              <IconWrap class="ml-auto" :icon="'mdi-pencil'" @click.native.stop="custInfoModalShow = true" />
            </div>

            <template v-else>
              <div>
                <TextField
                  ref="name"
                  v-model="form.name"
                  :rules="rules.name"
                  :outlined="false"
                  :hide-details="false"
                  :single-line="false"
                  :label="'Your Name'"
                />
              </div>
              <div>
                <TextField
                  ref="mobile"
                  v-model="form.mobile"
                  :rules="rules.mobile"
                  :outlined="false"
                  :hide-details="false"
                  :single-line="false"
                  :label="'Phone Number'"
                  :type="'number'"
                />
              </div>
              <div>
                <TextField
                  ref="email"
                  v-model="form.email"
                  :rules="rules.email"
                  :outlined="false"
                  :hide-details="false"
                  :single-line="false"
                  :label="'Email'"
                  :type="'email'"
                />
              </div>
            </template>
            <div>
              <TextField
                v-model="form.notes"
                :rules="rules.notes"
                :outlined="false"
                :hide-details="false"
                :single-line="false"
                :label="`${$t('lbl.remarks')}: eg. Less spicy, Less ice`"
                :placeholder="'Eg. Less spicy, Less ice'"
                :max-length="rules.notes.max"
                :counter="rules.notes.max"
              />
            </div>
          </div>
        </template>

        <template v-if="!isDeliveryArea || locNotAvailable">
          <div v-if="postcodeSearched" class="order-wrap pa-4 mb-4">
            <div class="font-1dot25r font-weight-bold text-center font-red-common">
              {{ $t('checkout.err.locNotAvailable') }}
            </div>
          </div>
        </template>

        <AppBtn class="btn-custom mt-4" block :height="42" :disabled="btnDisabled" @click="next">{{ $t('act.next') }}</AppBtn>
      </div>
    </ValidationObserver>

    <CommonModal
      v-model="custInfoModalShow"
      :modal-size="'modal-sm'"
      :title="'Customer Details'"
      :footer="false"
      :close-btn="false"
      :persistent="true"
    >
      <template #body-content>
        <ValidationObserver ref="observer2">
          <div>
            <TextField
              ref="name"
              v-model="form.name"
              :rules="rules.name"
              :outlined="false"
              :hide-details="false"
              :single-line="false"
              :label="'Your Name'"
            />
          </div>
          <div>
            <TextField
              ref="mobile"
              v-model="form.mobile"
              :rules="rules.mobile"
              :outlined="false"
              :hide-details="false"
              :single-line="false"
              :label="'Phone Number'"
              :type="'number'"
            />
          </div>
          <div>
            <TextField
              ref="email"
              v-model="form.email"
              :rules="rules.email"
              :outlined="false"
              :hide-details="false"
              :single-line="false"
              :label="'Email'"
              :type="'email'"
            />
          </div>

          <div class="text-right">
            <AppBtn class="btn-orange" @click="validateCustInfo">{{ $t('act.ok') }}</AppBtn>
          </div>
        </ValidationObserver>
      </template>
    </CommonModal>

    <v-overlay :value="overlay" :z-index="500">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { lookupApi } from '@/api/lookup';
import { orderDeliveryApi } from '@/api/order';
import { SCHEDULE_TYPE } from '@/common/enum/checkout';
import { mapState, mapGetters, mapMutations } from 'vuex';
import OrderMixin from '@/mixins/order';
import { getCurrentDate } from '@/util/tools';
import CommonModal from '@/components/Modal';
import moment from 'moment';

export default {
  components: {
    // PaymentChannel,
    CommonModal,
  },
  mixins: [OrderMixin],
  data() {
    return {
      postcodeLength: 5,
      postcodeDisabled: false,
      postcodeLoading: false,
      isDeliveryArea: false,
      postcodeSearched: false,
      locNotAvailable: false,

      form: {
        // unit: '',
        address: '',
        postcode: '',
        // city: '',

        mobile: '',
        name: '',
        email: '',
        notes: '',
        // state: 'SEL',
        // loc: null,
        scheduleType: SCHEDULE_TYPE.IN_ADVANCE,
        schedule: null,
        cartAmt: null,
        date: null,
        time: null,
      },
      rules: {
        // unit: { required: true },
        address: { required: true },
        postcode: { required: true, length: 5 },
        // city: { required: true },

        mobile: { required: true, min: 9, regex: /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/ },
        name: { required: true, min: 2 },
        email: { required: true, email: true },
        notes: { required: false, max: 50 },
        // state: { required: true },
        // loc: { required: true },
        scheduleType: { required: true },
        schedule: { required: true },
        date: { required: true },
        time: { required: true },
      },
      bizDateList: {},
      dateList: [],
      timeList: [],

      overlay: false,

      isReturnCust: false,
      custInfoModalShow: false,

      showDeliverySection: false,
      // stateList: [
      //   { text: 'Selangor', value: 'SEL' },
      //   { text: 'KL', value: 'KL' },
      // ],
      // saveDetails: false,
    };
  },
  computed: {
    ...mapState({
      onlinePayMethodList: state => state.lookup.onlinePayMethodList,
    }),
    ...mapGetters([
      'getCartItems',
      'getPlacedCartItems',
      'getDeliveryFee',
      'getAddress',
      'getPostcode',
      'getUserDisplayName',
      'getMobileNo',
      'getEmail',
    ]),
    btnDisabled() {
      return !this.isDeliveryArea;
    },
    SCHEDULE_TYPE() {
      return SCHEDULE_TYPE;
    },
    currentCartItems() {
      return !this.isUndefinedNullOrEmpty(this.getPlacedCartItems) ? this.getPlacedCartItems : this.getCartItems;
    },
    totalAmtBeforePromo() {
      if (this.isUndefinedNullOrEmpty(this.currentCartItems)) return 0;
      let total = 0;
      for (let item of this.currentCartItems) {
        total = total + item.qty * item.price;
      }
      return total;
    },
  },
  watch: {
    'form.postcode'(value) {
      if (value.length == 5) {
        this.postcodeDisabled = true;
        this.checkDeliveryArea();
      } else {
        if (this.postcodeSearched == true) {
          this.postcodeSearched = false;
          this.locNotAvailable = false;
          this.isDeliveryArea = false;

          this.form.unit = '';
          this.form.address = '';
          this.form.city = '';

          // this.form.scheduleType = null;
          // this.form.schedule = null;
        }
      }
    },
  },
  async created() {
    this.SET_DRAWER_TITLE(this.$t('header.checkout'));
    this.currentDate = getCurrentDate();

    if (!this.isUndefinedNullOrEmpty(this.getAddress)) this.form.address = this.getAddress;
    if (!this.isUndefinedNullOrEmpty(this.getPostcode)) this.form.postcode = this.getPostcode;

    if (!this.isUndefinedNullOrEmpty(this.getUserDisplayName)) this.form.name = this.getUserDisplayName;
    if (!this.isUndefinedNullOrEmpty(this.getMobileNo)) this.form.mobile = this.getMobileNo;
    if (!this.isUndefinedNullOrEmpty(this.getEmail)) this.form.email = this.getEmail;

    if (
      !this.isUndefinedNullOrEmpty(this.getUserDisplayName) &&
      !this.isUndefinedNullOrEmpty(this.getMobileNo) &&
      !this.isUndefinedNullOrEmpty(this.getEmail)
    ) {
      this.isReturnCust = true;
    }

    // if (this.isUndefinedNullOrEmpty(this.scheduleList)) this.getScheduleList();

    this.bizDateList = await this.getBizDate();
    this.dateList = this.processDateList(this.bizDateList);
  },
  methods: {
    ...mapMutations(['SET_DRAWER_TITLE', 'SET_DELIVERY_INFO_FORM', 'SET_QUOTATION_FORM']),
    getBizDate() {
      return orderDeliveryApi.getBizDate({}).then(res => {
        return res.data;
      });
    },
    processDateList(list) {
      const listByDates = list.bizDates;
      let bizDateList = [];
      for (let key in listByDates) {
        const dateByLoc = listByDates[key];
        if (!this.isUndefinedNullOrEmpty(dateByLoc) && !this.isUndefinedNullOrEmpty(dateByLoc.dates)) {
          const displayText = this.setTimeZone(key, 'YYYY-MM-DD (dddd)');

          bizDateList.push({
            text: displayText,
            value: Number(key),
          });
        }
      }
      return bizDateList;
    },
    async dateChg(data) {
      this.form.schedule = null;
      this.form.time = null;

      const slots = this.bizDateList.bizDates[data.toString()]?.dates;
      let timeList = [];
      for (let datetime of slots) {
        const time = this.setTimeZone(datetime, 'hh:mm A');
        timeList.push({
          text: time,
          value: datetime,
        });
      }
      this.timeList = timeList;
    },
    async timeChg() {
      this.form.time = this.setTimeZone(this.form.schedule, 'HHmm');

      if (!this.showDeliverySection) this.showDeliverySection = true;
    },
    async next() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      let data = {};
      try {
        this.overlay = true;

        // this.form.schedule = this.form.scheduleType == SCHEDULE_TYPE.IN_ADVANCE ? this.form.schedule : new Date().toISOString();
        const payload = {
          amt: this.totalAmtBeforePromo,
          bizDay: this.form.date,
          slot: this.form.time,
          address: this.form.address,
          postcode: this.form.postcode,
          country: 'MY',
          name: this.form.name,
          phone: this.form.mobile,
        };

        data = await this.getDeliveryQuotation(payload);
        if (!data.signature) {
          this.locNotAvailable = true;
        } else {
          this.locNotAvailable = false;
          const quotation = data.quotation;
          const delivery = quotation.delivery;
          this.SET_QUOTATION_FORM({
            storeId: delivery.storeId,
            storeName: delivery.storeName,
            eta: delivery.eta,
            totalFee: delivery.payload.totalFee,
            discount: delivery.payload.discount,
            deliveryId: delivery.payload.result.deliveryId,
            provider: delivery.payload.result.provider,
            userAddress: delivery.payload.result.points[1],
            quotation,
            quotationSignature: data.signature,
          });

          this.SET_DELIVERY_INFO_FORM(this.form);
          this.$emit('next');
        }

        this.overlay = false;
      } catch (error) {
        this.overlay = false;
      }
    },
    async checkDeliveryArea() {
      let deliveryArea = [];
      try {
        this.postcodeLoading = true;

        deliveryArea = await this.getDeliveryAreaByPostcode();

        this.postcodeLoading = false;
        this.postcodeDisabled = false;
      } catch (error) {
        this.postcodeLoading = false;
        this.postcodeDisabled = false;
      }
      if (deliveryArea?.length > 0) {
        // this.deliveryArea = deliveryArea.map(item => {
        //   return {
        //     text: item.name,
        //     value: item.id,
        //   }
        // })
        // if (this.deliveryArea.length == 1) {
        //   this.form.locId = this.deliveryArea[0].value;
        //   this.form.locName = this.deliveryArea[0].text;
        // }
        this.isDeliveryArea = true;

        let total = 0;
        for (let item of this.getCartItems) {
          total = total + item.qty * item.price;
        }
        // this.form.cartAmt = Number(this.priceFormat(total));

        // if (this.isUndefinedNullOrEmpty(this.getDeliveryFee?.amt) || this.isUndefinedNullOrEmpty(this.getDeliveryFee?.deliveryFee)) {
        //   this.showError('Delivery fee info missing');
        //   return;
        // }
        // if (this.form.cartAmt < this.getDeliveryFee.amt) {
        //   this.form.deliveryFee = this.getDeliveryFee.deliveryFee;
        // } else {
        //   this.form.deliveryFee = 0;
        //   this.deliveryFeeBefore = this.getDeliveryFee.deliveryFee;
        // }
      }
      this.postcodeSearched = true;
    },
    getDeliveryAreaByPostcode() {
      const params = {
        grp: this.form.postcode,
      };
      return lookupApi.getDeliveryAreaByPostcode(params).then(res => {
        return res.data;
      });
    },

    // scheduleTypeChg(value) {
    //   if (value == SCHEDULE_TYPE.IN_ADVANCE) {
    //     if (this.isUndefinedNullOrEmpty(this.scheduleList)) {
    //       this.getScheduleList();
    //     }
    //   }
    //   this.form.schedule = null;
    // },
    // scheduleChg() {},
    // getScheduleList() {
    //   return lookupApi.getScheduleList({ grp: 'delivery' }).then(res => {
    //     if (!this.isUndefinedNullOrEmpty(res.data)) {
    //       this.form.schedule = res.data[0].utc;
    //       this.scheduleList = res.data.map(item => {
    //         const localDateTime = this.setTimeZone(item.utc, 'YYYY-MM-DD h:mm A');
    //         const isSameDay = moment(getCurrentDate()).isSame(localDateTime.slice(0, 10));

    //         return {
    //           text: isSameDay ? item.name : localDateTime,
    //           value: item.utc,
    //         };
    //       });
    //     }
    //   });
    // },
    async validateCustInfo() {
      const valid = await this.$refs.observer2.validate();
      if (!valid) return;
      this.custInfoModalShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.note {
  color: var(--primary-black-color);
  font-size: 1.15rem;
  font-weight: 500;
}
.bottom-wrap {
  font-weight: 500;
}
.order-wrap {
  background-color: #fff;
  border: 5px solid var(--primary-black-color);
  border-radius: 5px;
}
.price-before {
  color: #616161;
}
.btn-custom {
  background-color: var(--primary-black-color) !important;
  color: #fabc23 !important;
  font-size: 1rem;
  font-weight: 500;
}
.cust-info-wrap {
  background-color: #fff;
  border: 3px solid var(--primary-black-color);
  border-radius: 5px;
}
</style>
