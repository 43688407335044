<template>
  <div class="h-100 px-2 py-1">
    <template v-if="showDeliveryChannel">
      <!-- <div class="d-flex align-center mb-2" @click="deliveryChannelModalShow = true"> -->
      <div class="d-flex align-center mb-2">
        <div class="note mr-2">{{ $t('map.deliveryChannel')[form.deliveryChannel] }}</div>
        <!-- <IconWrap :icon="'mdi-pencil'" small /> -->

        <!-- <div class="note mb-2">{{ $t('orders.lbl.deliveryChannel') }}</div> -->
        <!-- <Select
          v-model="form.deliveryChannel"
          :rules="rules.deliveryChannel"
          :options="$t('opt.deliveryChannel')"
          :outlined="false"
          :hide-details="false"
          :single-line="true"
          :label="$t('orders.lbl.deliveryChannel')"
          :placeholder="$t('lbl.select')"
          :clearable="false"
          @change="channelChg"
        /> -->
      </div>
      <v-divider class="my-divider mt-2 mb-4"></v-divider>
    </template>
    <Takeaway v-if="form.deliveryChannel == DELIVERY_CHANNEL.TAKEAWAY" />
    <Pickup v-else-if="form.deliveryChannel == DELIVERY_CHANNEL.PICK_UP" />
    <Delivery v-else-if="form.deliveryChannel == DELIVERY_CHANNEL.DELIVERY" />

    <DeliveryChannelModal v-model="deliveryChannelModalShow" @selected="selected" />
    <PromoCode v-if="promoListShow" />
  </div>
</template>
<script>
import { DELIVERY_CHANNEL, DELIVERY_CHANNEL_PATH, DELIVERY_STEP, PICK_UP_STEP } from '@/common/enum/checkout';
import Takeaway from './takeaway';
import Pickup from './pickup';
import Delivery from './delivery';
import DeliveryChannelModal from '@/views/Order/delivery-channel';
import PromoCode from '@/views/Promo/Code/components/PromoList.vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  components: {
    Takeaway,
    Pickup,
    PromoCode,
    Delivery,
    DeliveryChannelModal,
  },
  data() {
    return {
      form: {
        deliveryChannel: null,
      },
      rules: {
        deliveryChannel: { required: true },
      },
      deliveryChannelModalShow: false,
    };
  },
  computed: {
    ...mapState({
      promoListShow: state => state.promo.promoListShow,
    }),
    ...mapGetters(['getCartItems', 'getPlacedCartItems', 'getCartTotalPrice']),
    DELIVERY_CHANNEL() {
      return DELIVERY_CHANNEL;
    },
    showDeliveryChannel() {
      const params = this.$route.params;
      const query = this.$route.query;

      const condition1 = params.channel == DELIVERY_CHANNEL_PATH.TAKEAWAY;
      const condition2 = params.channel == DELIVERY_CHANNEL_PATH.PICK_UP && query.step == PICK_UP_STEP.PICK_UP_INFO;
      const condition3 = params.channel == DELIVERY_CHANNEL_PATH.DELIVERY && query.step == DELIVERY_STEP.DELIVERY_INFO;
      return condition1 || condition2 || condition3 ? true : false;
    },
  },
  created() {
    if (this.isUndefinedNullOrEmpty(this.getCartItems) && this.isUndefinedNullOrEmpty(this.getPlacedCartItems)) {
      this.navigatePush('MenuAll');
    }
    this.SET_DRAWER_TITLE(this.$t('header.checkout'));

    this.checkPromo({
      amt: this.getCartTotalPrice,
      codes: [],
    });

    const params = this.$route.params;
    if (!this.isUndefinedNullOrEmpty(params.channel)) {
      switch (params.channel) {
        case DELIVERY_CHANNEL_PATH.TAKEAWAY:
          this.form.deliveryChannel = DELIVERY_CHANNEL.TAKEAWAY;
          break;
        case DELIVERY_CHANNEL_PATH.PICK_UP:
          this.form.deliveryChannel = DELIVERY_CHANNEL.PICK_UP;
          break;
        case DELIVERY_CHANNEL_PATH.DELIVERY:
          this.form.deliveryChannel = DELIVERY_CHANNEL.DELIVERY;
          break;
        default:
          break;
      }
    } else {
      if (!this.isUndefinedNullOrEmpty(this.$route.query.storeId)) {
        this.form.deliveryChannel = DELIVERY_CHANNEL.TAKEAWAY;
        this.navigateReplace(
          'OrderCheckout',
          { channel: DELIVERY_CHANNEL_PATH.TAKEAWAY },
          { storeId: this.$route.query.storeId }
        );
      }
    }
  },
  methods: {
    ...mapMutations(['SET_DRAWER_TITLE']),
    ...mapActions(['checkPromo']),
    // channelChg(value) {
    //   switch(value) {
    //     case DELIVERY_CHANNEL.TAKEAWAY:
    //       this.navigateReplace('OrderCheckout', { channel: DELIVERY_CHANNEL_PATH.TAKEAWAY });
    //       break;
    //     case DELIVERY_CHANNEL.PICK_UP:
    //       this.navigateReplace('OrderCheckout', { channel: DELIVERY_CHANNEL_PATH.PICK_UP });
    //       break;
    //     case DELIVERY_CHANNEL.DELIVERY:
    //       this.navigateReplace('OrderCheckout', { channel: DELIVERY_CHANNEL_PATH.DELIVERY });
    //       break;
    //   }
    // },
    selected(value) {
      switch (value) {
        case DELIVERY_CHANNEL.TAKEAWAY:
          this.deliveryChannelModalShow = false;
          this.navigateReplace('OrderCheckout', { channel: DELIVERY_CHANNEL_PATH.TAKEAWAY });
          break;
        case DELIVERY_CHANNEL.PICK_UP:
          this.deliveryChannelModalShow = false;
          this.navigateReplace('OrderCheckout', { channel: DELIVERY_CHANNEL_PATH.PICK_UP }, { step: PICK_UP_STEP.PICK_UP_INFO });
          break;
        case DELIVERY_CHANNEL.DELIVERY:
          this.deliveryChannelModalShow = false;
          this.navigateReplace(
            'OrderCheckout',
            { channel: DELIVERY_CHANNEL_PATH.DELIVERY },
            { step: DELIVERY_STEP.DELIVERY_INFO }
          );
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.note {
  color: var(--primary-black-color);
  font-size: 1.5rem;
  font-weight: 500;
}
</style>
