<template>
  <div>
    <ValidationObserver ref="observer">
      <div class="mb-4">
        <!-- <div class="font-13 mb-2">
          * Enter postcode to check available locations
        </div>
        <div>
          <TextField
            v-model="form.postcode"
            :rules="rules.postcode"
            :max-length="postcodeLength"
            :outlined="false"
            :hide-details="false"
            :single-line="false"
            :label="$t('profile.lbl.postcode')"
            :loading="postcodeLoading"
            :read-only="postcodeDisabled"
          />
        </div> -->

        <!-- <template v-if="isPickupArea"> -->
        <div>
          <div class="note">Location</div>

          <Select
            v-model="form.locId"
            :rules="rules.locId"
            :options="locList"
            :outlined="false"
            :single-line="true"
            :label="'Select Location'"
            :placeholder="$t('pickup.lbl.pickupLocation')"
            :clearable="false"
            @change="locChg"
          />
          <!-- :return-obj="true" -->

          <div class="note mt-4">
            {{ $t('lbl.date') }}
          </div>

          <Select
            v-model="form.date"
            :rules="rules.date"
            :options="dateList"
            :outlined="false"
            :single-line="true"
            :label="$t('lbl.date')"
            :placeholder="$t('pickup.lbl.selectDate')"
            :clearable="false"
            @change="dateChg"
          />

          <div class="note mt-4">
            {{ $t('lbl.time') }}
          </div>

          <Select
            v-model="form.time"
            :rules="rules.time"
            :options="timeList"
            :outlined="false"
            :single-line="true"
            :label="$t('lbl.time')"
            :placeholder="$t('pickup.lbl.selectTimeSlot')"
            :clearable="false"
            @change="timeChg"
          />

          <!-- <ValidationProvider v-slot="{ errors }" :name="$t('pickup.lbl.pickupTime')" :rules="rules.scheduleType">
              <v-radio-group class="radio-option-wrap mb-2" row v-model="form.scheduleType" @change="scheduleTypeChg" :error-messages="errors[0]">
                <v-radio :label="$t('checkout.lbl.orderNow')" :value="SCHEDULE_TYPE.NOW">
                </v-radio>
                <v-radio :label="$t('checkout.lbl.orderInAdvance')" :value="SCHEDULE_TYPE.IN_ADVANCE">
                </v-radio>
                <template v-if="form.scheduleType == SCHEDULE_TYPE.IN_ADVANCE">
                  <div class="w-100 mt-1">
                    <Select
                      v-model="form.schedule"
                      :rules="rules.schedule"
                      :options="scheduleList"
                      :outlined="false"
                      :single-line="true"
                      :label="$t('pickup.lbl.pickupTime')"
                      :placeholder="$t('pickup.lbl.selectTimeSlot')"
                      :clearable="false"
                      @change="scheduleChg"
                    />
                  </div>
                </template>
              </v-radio-group>
            </ValidationProvider> -->
        </div>
        <!-- </template> -->
        <!-- <template v-else>
          <div v-if="pickupSearched" class="order-wrap pa-4 mb-4">
            <div class="font-1dot25r font-weight-bold text-center font-red-common">
              {{ $t('checkout.err.locNotAvailable') }}
            </div>
          </div>
        </template> -->
        <AppBtn class="btn-custom mt-6" block :height="42" :disabled="btnDisabled" @click="next">{{ $t('act.next') }}</AppBtn>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { orderPickupLocApi } from '@/api/order';
import { lookupApi } from '@/api/lookup';
import { offerApi, quotationApi } from '@/api/offer';
import { SCHEDULE_TYPE } from '@/common/enum/checkout';
import { mapGetters, mapMutations } from 'vuex';
import { getCurrentDate } from '@/util/tools';
import moment from 'moment';

export default {
  components: {},
  data() {
    return {
      postcodeLength: 5,
      isPickupArea: false,
      pickupSearched: false,
      postcodeDisabled: false,
      postcodeLoading: false,
      form: {
        postcode: '',
        locId: null,
        // locName: '',
        scheduleType: SCHEDULE_TYPE.IN_ADVANCE,
        schedule: null,
        time: null,
        date: null,
        // rate: {
        //   disc: 0, // discount percentage
        //   deliveryDisc: 0,
        // },
        quotation: {},
        quotationSignature: null,
      },
      locList: [],
      rules: {
        postcode: { required: true, max: 5 },
        locId: { required: true },
        scheduleType: { required: true },
        schedule: { required: true },
        time: { required: true },
        date: { required: true },
      },

      scheduleList: {},
      dateList: [],
      timeList: [],
    };
  },
  computed: {
    ...mapGetters(['getPostcode', 'getPreSelectedLoc', 'getCartItems', 'getPlacedCartItems']),
    btnDisabled() {
      return !this.isPickupArea;
    },
    SCHEDULE_TYPE() {
      return SCHEDULE_TYPE;
    },
    currentCartItems() {
      return !this.isUndefinedNullOrEmpty(this.getPlacedCartItems) ? this.getPlacedCartItems : this.getCartItems;
    },
    totalAmtBeforePromo() {
      if (this.isUndefinedNullOrEmpty(this.currentCartItems)) return 0;
      let total = 0;
      for (let item of this.currentCartItems) {
        total = total + item.qty * item.price;
      }
      return total;
    },
  },
  watch: {
    'form.postcode'(value) {
      if (value.length == 5) {
        this.postcodeDisabled = true;
        this.checkPickupArea();
      } else {
        if (this.pickupSearched == true) {
          this.pickupSearched = false;
          this.isPickupArea = false;

          this.form.locId = null;
          this.form.scheduleType = null;
          this.form.schedule = null;
        }
      }
    },
  },
  async created() {
    this.SET_DRAWER_TITLE(this.$t('header.checkout'));
    this.currentDate = getCurrentDate();

    if (!this.isUndefinedNullOrEmpty(this.getPreSelectedLoc)) {
      this.form.locId = Number(this.getPreSelectedLoc);

      this.scheduleList = await this.getOfferList();
      this.dateList = this.processDateList(this.scheduleList, this.form.locId);
    }

    // if (!this.isUndefinedNullOrEmpty(this.getPostcode)) this.form.postcode = this.getPostcode;
    this.form.postcode = '52100'; // As per discussed, hardcode for now
    // if (this.isUndefinedNullOrEmpty(this.scheduleList)) this.getScheduleList();
  },
  methods: {
    ...mapMutations(['SET_DRAWER_TITLE', 'SET_PICKUP_INFO_FORM']),
    getOfferList() {
      return offerApi.getOfferList({}).then(res => {
        return res.data;
      });
    },
    getPickupQuotation(amt, bizDay, locId, slot) {
      const data = {
        amt,
        bizDay,
        locId,
        slot,
      };
      return quotationApi.getPickupQuotation(data).then(res => {
        const data = res.data;
        if (!this.isUndefinedNullOrEmpty(data?.quotation)) {
          this.form.quotation = data.quotation;
        }

        if (this.isUndefinedNullOrEmpty(data?.signature)) {
          this.showError('Sorry, we are unable to serve your pickup location temporarily. Please select another location.');
        } else {
          this.form.quotationSignature = data.signature;
        }
        return data;
      });
    },
    processDateList(list, locId) {
      const listByDates = list.bizDays;
      let scheduleList = [];
      for (let key in listByDates) {
        const dateByLoc = listByDates[key].locs[locId];
        if (!this.isUndefinedNullOrEmpty(dateByLoc) && !this.isUndefinedNullOrEmpty(dateByLoc.slots)) {
          const displayText = this.setTimeZone(key, 'YYYY-MM-DD (dddd)');

          scheduleList.push({
            text: displayText,
            value: Number(key),
          });
        }
      }
      return scheduleList;
    },
    async next() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      await this.getPickupQuotation(this.totalAmtBeforePromo, this.form.date, this.form.locId, this.form.time);

      this.SET_PICKUP_INFO_FORM(this.form);
      this.$emit('next');
    },
    async checkPickupArea() {
      let locList = [];
      try {
        this.postcodeLoading = true;

        locList = await this.getPickupLocDdl();

        this.postcodeLoading = false;
        this.postcodeDisabled = false;
      } catch (error) {
        this.postcodeLoading = false;
        this.postcodeDisabled = false;
      }
      if (locList?.length > 0) {
        this.locList = locList
          .filter(item => item.status == 1)
          .map(item => {
            return {
              text: item.name,
              value: item.id,
            };
          });
        if (this.locList.length == 1) {
          this.form.locId = this.locList[0].value;
          // this.form.locName = this.locList[0].text;
        } else {
          // If locId already set in menu all page(Coming from other places), check here to make sure that the locId is valid & available
          if (this.form.locId) {
            const locFound = this.locList.find(item => item.value == this.form.locId);
            if (!locFound) this.form.locId = null;
          }
        }
        this.isPickupArea = true;
      } else {
        if (this.form.locId) this.form.locId = null;
      }
      this.pickupSearched = true;
    },
    getPickupLocDdl() {
      const params = {
        postcode: this.form.postcode,
      };
      return orderPickupLocApi.ddl(params).then(res => {
        return res.data;
      });
    },
    async locChg(data) {
      this.form.date = null;
      this.form.time = null;

      if (this.isUndefinedNullOrEmpty(this.scheduleList)) {
        this.scheduleList = await this.getOfferList();
      }
      this.dateList = this.processDateList(this.scheduleList, data);
      this.timeList = [];
      // this.form.locId = data.value;
      // this.form.locName = data.text;
    },
    async dateChg(data) {
      this.form.time = null;

      const slots = this.scheduleList.bizDays[data.toString()]?.locs[this.form.locId]?.slots;
      let timeList = [];
      for (let key in slots) {
        const item = slots[key];
        timeList.push({
          text: item.name,
          value: key,
          date: item.date,
        });
      }
      this.timeList = timeList;
    },
    async timeChg(data) {
      this.form.schedule = this.scheduleList.bizDays[this.form.date.toString()]?.locs[this.form.locId]?.slots[
        data.toString()
      ].date;
    },
    // scheduleTypeChg(value) {
    //   if (value == SCHEDULE_TYPE.IN_ADVANCE) {
    //     if (this.isUndefinedNullOrEmpty(this.scheduleList)) {
    //       this.getScheduleList();
    //     }
    //   }
    //   this.form.schedule = null;
    // },
    // scheduleChg() {

    // },
    // getScheduleList() {
    //   return lookupApi.getScheduleList({ grp: 'pickup' }).then(res => {
    //     if (!this.isUndefinedNullOrEmpty(res.data)) {
    //       this.form.schedule = res.data[0].utc;
    //       this.scheduleList = res.data.map(item => {
    //         const localDateTime = this.setTimeZone(item.utc, 'YYYY-MM-DD h:mm A');
    //         const isSameDay = moment(getCurrentDate()).isSame(localDateTime.slice(0, 10));

    //         return {
    //           text: isSameDay ? item.name : localDateTime,
    //           value: item.utc,
    //         };
    //       });
    //     }
    //   });
    // },
  },
};
</script>
<style lang="scss" scoped>
.note {
  color: var(--primary-black-color);
  font-size: 1.15rem;
  font-weight: 500;
}
.bottom-wrap {
  font-weight: 500;
}
.order-wrap {
  background-color: #fff;
  border: 5px solid var(--primary-black-color);
  border-radius: 5px;
}
.btn-custom {
  background-color: var(--primary-black-color) !important;
  color: #fabc23 !important;
  font-size: 1rem;
  font-weight: 500;
}
</style>
