<template>
  <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card class="d-flex flex-column">
      <v-toolbar class="flex-grow-0" color="var(--primary-color)">
        <v-btn icon @click="close">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Select Promo Code</v-toolbar-title>
      </v-toolbar>
      <div class="d-flex flex-column px-3 flex-grow-1">
        <div class="d-flex align-center py-2 mb-2">
          <TextField v-model="promoCode" class="flex-grow-1 mr-2" :label="$t('promo.lbl.enterPromoCode')" />
          <AppBtn class="btn-black" :loading="loading" :disabled="disabledApplyPromo" @click="applyPromo(promoCode)">{{
            $t('act.apply')
          }}</AppBtn>
        </div>
        <div class="flex-grow-1">
          <h4 class="mb-2">Promo Codes</h4>
          <template v-if="Object.keys(getPromoList).length > 0">
            <div
              v-for="(value, key, index) in getPromoList"
              :key="index"
              class="code-wrap d-flex align-center cursor-pointer mb-2"
              @click="updateSelectedPromo(key)"
            >
              <div class="code-box d-flex align-center justify-center">
                {{ key }}
              </div>
              <div class="flex-grow-1 d-flex flex-column justify-center pa-2">
                <div>{{ value.info.subtitle }}</div>
                <div>Min Spend {{ `${$t('lbl.rm')}${value.info.minAmt}` }}</div>
                <div>Valid Till: {{ setTimeZone(value.info.expiry) }}</div>
              </div>
              <div class="pr-3">
                <IconWrap
                  :icon="tempSelectedPromo == key ? iconChecked : iconNotChecked"
                  :size="25"
                  :color="tempSelectedPromo == key ? iconCheckedColor : iconNotCheckedColor"
                />
              </div>
            </div>
          </template>

          <div v-else class="code-wrap d-flex justify-center align-center mb-2">
            <div>
              No available promo codes
            </div>
          </div>
        </div>
        <div class="py-2">
          <AppBtn class="btn-black" block @click="selectPromo">{{ $t('act.ok') }}</AppBtn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  props: {
    // value: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      promoCode: '',
      loading: false,
      iconNotChecked: 'mdi-checkbox-blank-circle-outline',
      iconChecked: 'mdi-check-circle',
      iconNotCheckedColor: 'rgba(0,0,0,0.14)',
      iconCheckedColor: 'var(--green)',
      tempSelectedPromo: '',
    };
  },
  computed: {
    ...mapState({
      selectedPromo: state => state.promo.selectedPromo,
      promoListShow: state => state.promo.promoListShow,
    }),
    ...mapGetters(['getPromoList', 'getCartItems']),
    show: {
      get() {
        return this.promoListShow;
      },
      show(value) {
        // this.$emit('input', value);
        this.SET_PROMO_LIST_SHOW(value);
      },
    },
    disabledApplyPromo() {
      return this.promoCode.length < 4;
    },
    totalPrice() {
      let total = 0;
      for (let item of this.getCartItems) {
        total = total + item.qty * item.price;
      }
      return total;
    },
  },
  created() {
    this.tempSelectedPromo = this.selectedPromo;
  },
  methods: {
    ...mapMutations(['SET_PROMO_LIST_SHOW', 'SET_SELECTED_PROMO', 'SET_SELECTED_PROMO_INFO']),
    ...mapActions(['checkPromo']),
    async applyPromo(promoCode) {
      try {
        this.loading = true;
        await this.checkPromo({
          amt: this.totalPrice,
          codes: promoCode.toUpperCase(),
        });
        this.promoCode = '';
        this.loading = false;
      } catch (error) {
        this.showError(error);
        this.loading = false;
      }
    },
    close() {
      this.SET_PROMO_LIST_SHOW(false);
    },
    updateSelectedPromo(selectedPromo) {
      if (this.tempSelectedPromo == selectedPromo) {
        this.tempSelectedPromo = '';
      } else {
        this.tempSelectedPromo = selectedPromo;
      }
    },
    selectPromo() {
      if (this.isUndefinedNullOrEmpty(this.tempSelectedPromo)) {
        this.SET_SELECTED_PROMO('');
        this.SET_SELECTED_PROMO_INFO({});
      } else {
        this.SET_SELECTED_PROMO(this.tempSelectedPromo);
        this.SET_SELECTED_PROMO_INFO(this.getPromoList[this.tempSelectedPromo]);
      }
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.code-wrap {
  border: 1px solid #e8e8e8;
  box-shadow: 0.125rem 0.125rem 0.3125rem rgb(0 0 0 / 7%);
  height: 90px;
}
.code-box {
  background-color: var(--primary-color);
  max-width: 90px;
  width: 100%;
  height: 100%;
}
</style>
