import Api from '../api';

export const offerApi = {
  getOfferList(params) {
    return Api.get('gis/loc/offer/list', params);
  },
  getOfferQuotation(data) {
    return Api.post('gis/loc/offer/quotation', data);
  },
};

export const quotationApi = {
  getTakeawayQuotation(data) {
    return Api.post('takeaway/quotation', data);
  },
  getPickupQuotation(data) {
    return Api.post('pickup/quotation', data);
  },
  getDeliveryQuotation(data) {
    return Api.post('delivery/quotation', data);
  },
}