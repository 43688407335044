<template>
  <div>
    <ValidationObserver ref="observer">
      <!-- <div class="mb-6">
        <div class="note mb-2">You are buying from store:</div>

        <div class="mb-2">
          <Select
            v-model="form.storeId"
            :rules="rules.storeId"
            :options="storeDdlListWithPrefix"
            :outlined="false"
            :hide-details="false"
            :single-line="true"
            :label="$t('store.lbl.selectStore')"
            :placeholder="$t('store.lbl.selectStore')"
            :clearable="false"
            :read-only="validStoreFound"
            @change="storeChg"
          />
        </div>
      </div> -->
      <div>
        <div class="mb-2">
          <v-expansion-panels class="order-summ" accordion v-model="orderSummaryPanel">
            <v-expansion-panel :style="isUndefinedNullOrEmpty(orderSummaryPanel) ? expansionPanelCustStyle : {}">
              <v-expansion-panel-header class="px-3 py-2">
                <div class="d-flex justify-space-between align-center">
                  <div class="font-weight-medium font-1dot1r">Order Summary</div>
                  <!-- <div v-if="orderSummaryPanel == null" class="font-weight-medium">
                    <span v-if="subtotal != totalAmt" class="line-through font-1dot1r font-grey-dark-1 mr-2">{{
                      `${$t('lbl.rm')}${priceFormat(subtotal)}`
                    }}</span>
                    <span class="font-red-common font-1dot1r">{{ `${$t('lbl.rm')}${priceFormat(totalAmt)}` }}</span>
                  </div> -->
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <Summary
                  :cart-items="currentCartItems"
                  :foc-cart-items="currentFocCartItems"
                  :amt-info="amtInfo"
                  :offer-obj="offerObj"
                  :order-type="DELIVERY_CHANNEL.DELIVERY"
                  :delivery-time="deliveryInfo.schedule"
                  :is-delivery="true"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div
            v-if="isUndefinedNullOrEmpty(orderSummaryPanel)"
            class="px-3 pb-2 white"
            style="border-bottom-left-radius: 4px; border-bottom-right-radius: 4px;"
          >
            <v-divider class="mb-3"></v-divider>

            <div class="d-flex justify-space-between align-center mb-2">
              <div class="font-weight-medium font-1dot1r">{{ $t('orders.lbl.subtotal') }}</div>
              <div class="font-weight-medium font-1dot1r">{{ `${$t('lbl.rm')}${priceFormat(amtInfo.subtotal)}` }}</div>
            </div>
            <div class="d-flex justify-space-between align-center mb-2">
              <div class="font-weight-medium font-1dot1r">{{ $t('orders.lbl.deliveryFee') }}</div>
              <div class="font-weight-medium">
                <span v-if="amtInfo.discount" class="line-through mr-2" style="color:var(--grey-dark-1)">{{
                  `${$t('lbl.rm')}${priceFormat(amtInfo.deliveryFeeBeforeDisc)}`
                }}</span>
                <span class="font-1dot1r">{{ `${$t('lbl.rm')}${priceFormat(amtInfo.deliveryFeeAfterDisc)}` }}</span>
              </div>
            </div>
            <div v-if="offerObj.discountAmt > 0" class="d-flex justify-space-between align-center mb-2">
              <div class="font-weight-medium font-1dot1r">Discount</div>
              <div class="font-weight-medium font-1dot1r">{{ `- ${$t('lbl.rm')}${priceFormat(offerObj.discountAmt)}` }}</div>
            </div>

            <v-divider class="my-3"></v-divider>

            <div class="d-flex justify-space-between align-center">
              <div class="font-weight-medium font-1dot1r">{{ $t('lbl.total') }}</div>
              <div class="font-weight-medium font-red-common font-1dot1r">
                {{ `${$t('lbl.rm')}${priceFormat(amtInfo.totalAmt)}` }}
              </div>
            </div>
          </div>
        </div>

        <v-expansion-panels class="order-summ" accordion v-model="deliveryInfoPanel">
          <v-expansion-panel>
            <v-expansion-panel-header class="px-3 py-2">
              <div class="d-flex justify-space-between align-center">
                <div class="font-weight-medium font-1dot1r">Delivery Info</div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mb-3"></v-divider>

              <div class="d-flex mb-2">
                <div class="delivery-info-label" cols="3">{{ $t('profile.lbl.address') }}:</div>
                <div class="font-weight-medium" cols="9">{{ deliveryInfo.address }}</div>
              </div>
              <!-- <div class="d-flex mb-2">
                <div class="w-30" cols="3">{{ $t('profile.lbl.postcode') }}:</div>
                <div class="w-70 font-weight-medium" cols="9">{{ deliveryInfo.postcode }}</div>
              </div> -->
              <div class="d-flex mb-2">
                <div class="delivery-info-label" cols="3">{{ $t('delivery.lbl.eta') }}:</div>
                <div class="font-weight-medium" cols="9">
                  <span>{{ setTimeZone(quotationInfo.eta, timeFormat) }} by </span>
                  <span class="font-red-common">{{ quotationInfo.provider }}</span>
                </div>
              </div>
              <!-- <div class="d-flex">
                <div class="w-30" cols="3">Delivery By:</div>
                <div class="w-70 font-weight-medium" cols="9">{{ quotationInfo.provider }}</div>
              </div> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <v-divider class="mt-3 mb-2"></v-divider>

      <div class="note mb-2">Payment Method</div>

      <div class="mb-3">
        <ValidationProvider v-slot="{ errors }" :name="$t('payment.lbl.paymentChannel')" :rules="rules.payChannel">
          <PaymentChannel class="mb-1" v-model="form.payMethod" :list="onlinePayMethodList" />
          <span v-if="!isUndefinedNullOrEmpty(errors)" class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <PaymentForm ref="paymentForm" :data="pgFormData" />
      <AppBtn class="btn-black" block :height="42" :loading="loading" @click="placeOrder">{{
        `${$t('cart.act.placeOrder')} (${$t('lbl.rm')}${priceFormat(totalAmt)})`
      }}</AppBtn>

      <CommonModal
        v-model="quotationModalShow"
        :modal-size="'modal-sm'"
        :title="'Delivery Info Updated'"
        :footer="false"
        :close-btn="true"
      >
        <template #body>
          <div class="pa-3">
            <div class="font-1dot1r font-weight-regular mt-2 mb-4">
              Delivery info and fee have been updated.
            </div>
            <div class="text-right">
              <AppBtn class="btn-orange" @click="quotationModalShow = false">{{ $t('act.ok') }}</AppBtn>
            </div>
          </div>
        </template>
      </CommonModal>

      <CommonModal
        v-model="deliveryAreaModalShow"
        :modal-size="'modal-sm'"
        :title="'Location Not Available'"
        :footer="false"
        :close-btn="true"
      >
        <template #body>
          <div class="pa-3">
            <div class="font-1dot1r font-weight-regular mt-2 mb-4">
              {{ $t('checkout.err.locNotAvailable') }}
            </div>
            <div class="text-right">
              <AppBtn
                class="btn-orange"
                @click="
                  deliveryAreaModalShow = false;
                  $emit('back');
                "
                >{{ $t('act.ok') }}</AppBtn
              >
            </div>
          </div>
        </template>
      </CommonModal>
    </ValidationObserver>
  </div>
</template>
<script>
import PaymentChannel from '../../../payment/channel';
import PaymentForm from '../../../payment/form';
import { DELIVERY_CHANNEL, PAY_OPTION } from '@/common/enum/checkout';
import { orderApi } from '@/api/order';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { SCHEDULE_TYPE } from '@/common/enum/checkout';
import OrderMixin from '@/mixins/order';
import CommonModal from '@/components/Modal';
import Summary from '@/views/Order/summary';
import moment from 'moment';

export default {
  components: {
    PaymentChannel,
    PaymentForm,
    Summary,
    CommonModal,
  },
  mixins: [OrderMixin],
  data() {
    return {
      form: {
        payMethod: null,
        notes: '',
      },
      loading: false,

      pgFormData: {
        actionUrl: '',
        merchantId: '',
        amount: '',
        orderId: '',
        billName: '',
        billEmail: '',
        billMobile: '',
        billDesc: '',
        country: '',
        vcode: '',
        currency: '',
        channel: '',
        returnurl: '',
        callbackurl: '',
      },

      payOption: PAY_OPTION.PAY_ONLINE,
      storeName: '',

      rules: {
        payChannel: { required: true },
        notes: { required: false, max: 50 },
      },

      orderSummaryPanel: null,
      deliveryInfoPanel: 0,
      timeFormat: 'ddd, D MMM, hh:mm A',

      quotationModalShow: false,
      deliveryAreaModalShow: false,

      expansionPanelCustStyle: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    };
  },
  computed: {
    ...mapState({
      storeDdlList: state => state.store.storeDdlList,
      onlinePayMethodList: state => state.lookup.onlinePayMethodList,
      deliveryInfo: state => state.checkout.delivery.form.deliveryInfo,
      quotationInfo: state => state.checkout.delivery.form.quotationInfo,
    }),
    ...mapGetters([
      'getCartItems',
      'getFocCartItems',
      'getPlacedCartItems',
      'getPlacedFocCartItems',
      'getUserDisplayName',
      'getMobileNo',
      'getEmail',
    ]),
    PAY_OPTION() {
      return PAY_OPTION;
    },
    DELIVERY_CHANNEL() {
      return DELIVERY_CHANNEL;
    },
    currentCartItems() {
      return !this.isUndefinedNullOrEmpty(this.getPlacedCartItems) ? this.getPlacedCartItems : this.getCartItems;
    },
    currentFocCartItems() {
      return !this.isUndefinedNullOrEmpty(this.getPlacedFocCartItems) ? this.getPlacedFocCartItems : this.getFocCartItems;
    },
    // Before offer & delivery fee & delivery fee discount
    subtotal() {
      let total = 0;
      for (let item of this.currentCartItems) {
        total = total + item.qty * item.price;
      }
      return total;
    },
    deliveryFeeBeforeDisc() {
      if (this.isUndefinedNullOrEmpty(this.quotationInfo)) return 0;
      return this.quotationInfo.totalFee + this.quotationInfo.discount;
    },
    deliveryFeeAfterDisc() {
      if (this.isUndefinedNullOrEmpty(this.quotationInfo)) return 0;
      return this.quotationInfo.totalFee;
    },
    // totalAmt() {
    //   if (this.isUndefinedNullOrEmpty(this.quotationInfo)) return 0;
    //   return this.subtotal + this.quotationInfo.totalFee;
    // },
    totalAmt() {
      return this.subtotal - this.offerObj?.discountAmt + this.deliveryFeeAfterDisc;
    },
    offerObj() {
      if (this.isUndefinedNullOrEmpty(this.quotationInfo.quotation)) return {};
      return {
        discountedAmt: this.quotationInfo.quotation.amt,
        discountRate: this.quotationInfo.quotation.rate.disc, // eg. 0.2 . Not in percentage.
        discountAmt: this.quotationInfo.quotation.rate.discAmt,
      };
    },
    amtInfo() {
      return {
        subtotal: this.subtotal,
        discount: this.quotationInfo.discount,
        deliveryFeeBeforeDisc: this.deliveryFeeBeforeDisc,
        deliveryFeeAfterDisc: this.deliveryFeeAfterDisc,
        totalAmt: this.totalAmt,
      };
    },
  },
  watch: {
    deliveryAreaModalShow(value) {
      if (!value) {
        this.$emit('back');
      }
    },
  },
  created() {
    if (this.isUndefinedNullOrEmpty(this.onlinePayMethodList)) this.fetchOnlinePayMethodList();
  },
  methods: {
    ...mapMutations([
      'SET_STORE_ID',
      'SET_PRE_SELECTED_CHANNEL_FLAG',
      'SET_STORE_NAME',
      'SET_USER_DISPLAY_NAME',
      'SET_USER_MOBILE_NO',
      'SET_EMAIL',
      'SET_ORDER_USER_ID',
      'SET_ADDRESS',
      'SET_POSTCODE',
      'SET_QUOTATION_FORM',
    ]),
    ...mapActions([
      'clearCart',
      'clearFocCart',
      'setOrderHistory',
      'fetchOnlinePayMethodList',
      'setPlacedCart',
      'setPlacedFocCart',
    ]),
    payOptionChg(value) {
      if (value == PAY_OPTION.PAY_ONLINE) {
        setTimeout(() => {
          window.scrollBy(0, 100);
        }, 100);
      }
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    async placeOrder() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      if (this.isUndefinedNullOrEmpty(this.currentCartItems)) {
        this.showError('Your cart is empty');
        return;
      }

      let data = {
        storeId: this.quotationInfo.storeId, // Pickup uses locId. Pass storeId: 0 here to bypass API check.
        address: this.deliveryInfo.address,
        postcode: this.deliveryInfo.postcode,
        deliveryId: this.quotationInfo.deliveryId,
        deliveryFee: this.quotationInfo.totalFee,
        // city: this.deliveryInfo.city,
        quotationSignature: this.quotationInfo.quotationSignature,
        doDate: this.deliveryInfo.schedule,
        channel: DELIVERY_CHANNEL.DELIVERY,
        amt: Number(this.priceFormat(this.totalAmt)),
        discAmt: this.offerObj?.discountAmt,
        mobile: this.deliveryInfo.mobile.trim(),
        email: this.deliveryInfo.email.trim(),
        name: this.deliveryInfo.name.trim(),
        items: [...this.processItems(this.currentCartItems), ...this.processItems(this.currentFocCartItems)],
        payMethod: this.form.payMethod,
        notes: this.deliveryInfo.notes,
      };

      this.loading = true;

      return orderApi
        .place(data)
        .then(async res => {
          if (res.ret == '0') {
            const resData = res.data;

            // this.SET_STORE_NAME(this.storeName);
            this.SET_STORE_ID(null); // Clear store id so that it won't store forever
            this.SET_PRE_SELECTED_CHANNEL_FLAG(1); // set flag = 1 so that the modal won't pop up when user back to menu all
            this.SET_USER_DISPLAY_NAME(data.name);
            this.SET_USER_MOBILE_NO(data.mobile);
            this.SET_ADDRESS(data.address);
            this.SET_POSTCODE(data.postcode);
            this.SET_EMAIL(data.email);
            this.SET_ORDER_USER_ID(resData.userId);

            // this.setOrderHistory({
            //   amt: resData.amt,
            //   deliveryFee: this.deliveryInfo.deliveryFee,
            //   orderId: resData.orderId,
            //   seq: resData.seq,
            //   curSeq: resData.curSeq,
            //   userId: resData.userId,
            //   time: moment.parseZone(new Date()).format('h:mma'),
            //   data: [...this.getCartItems, ...this.getFocCartItems],
            //   cancelled: 0,
            //   status: resData.status,
            //   dChannel: DELIVERY_CHANNEL.DELIVERY,
            //   storeName: '',
            //   locName: '',
            //   scheduleType: this.deliveryInfo.scheduleType,
            //   schedule: this.deliveryInfo.schedule,
            // });

            // this.clearCart();
            // this.clearFocCart();
            this.setPlacedCart(this.currentCartItems);
            this.setPlacedFocCart(this.currentFocCartItems);
            // this.CLEAR_DELIVERY_FEE();

            this.postToPg(resData);
          } else {
            if (res.ret == 'InvalidSignature') {
              const payload = {
                amt: this.subtotal,
                bizDay: this.quotationInfo.quotation.bizDay,
                slot: this.quotationInfo.quotation.slot,
                address: this.deliveryInfo.address,
                postcode: this.deliveryInfo.postcode,
                country: 'MY',
                name: this.deliveryInfo.name.trim(),
                phone: this.deliveryInfo.mobile.trim(),
              };

              const quotationRes = await this.getDeliveryQuotation(payload);

              if (!quotationRes.signature) {
                this.deliveryAreaModalShow = true;
              } else {
                const quotation = quotationRes.quotation;

                this.SET_QUOTATION_FORM({
                  storeId: quotation.delivery.storeId,
                  storeName: quotation.delivery.storeName,
                  eta: quotation.delivery.eta,
                  totalFee: quotation.delivery.payload.totalFee,
                  discount: quotation.delivery.payload.discount,
                  deliveryId: quotation.delivery.payload.result.deliveryId,
                  provider: quotation.delivery.payload.result.provider,
                  userAddress: quotation.delivery.payload.result.points[1],
                  quotation,
                  quotationSignature: quotationRes.signature,
                });

                this.orderSummaryPanel = 0;
                this.deliveryInfoPanel = 0;

                this.quotationModalShow = true;
              }
            } else if (res.ret == 'InvalidRequest') {
              if (res.data?.cause[0]?.err) {
                this.showError(res.data.cause[0].err);
              } else {
                this.showError(res.msg);
              }
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    postToPg(data) {
      this.pgFormData.actionUrl = data.tx.url;
      this.pgFormData.merchantId = data.tx.merchantId;
      this.pgFormData.amount = this.priceFormat(data.amt); // Need to pass .00 to Razer . Eg. 1.00, 2.00
      this.pgFormData.orderId = data.tx.orderNo;
      this.pgFormData.billName = this.deliveryInfo.name.trim();
      this.pgFormData.billEmail = this.deliveryInfo.email.trim();
      this.pgFormData.billMobile = this.deliveryInfo.mobile.trim();
      this.pgFormData.country = data.tx.country;
      this.pgFormData.vcode = data.tx.vcode;
      this.pgFormData.currency = data.tx.curr;
      this.pgFormData.channel = data.tx.channel;

      this.$nextTick(() => {
        this.loading = true;
        this.$refs['paymentForm'].postToPg();
        this.loading = false;
      });
    },
    processItems(data) {
      return data.map(item => {
        return {
          itemId: item.menuItemId,
          qty: item.qty,
          items: !this.isUndefinedNullOrEmpty(item.items)
            ? item.items.map(innerItem => {
                return {
                  itemId: innerItem.itemId,
                  qty: innerItem.qty,
                };
              })
            : null,
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../../../styles/mixin.scss';
.note {
  color: var(--primary-black-color);
  font-size: 1.15rem;
  font-weight: 500;
}
.pay-option-wrap {
  .v-radio::v-deep {
    .v-icon {
      font-size: 30px;
    }
    .v-label {
      font-size: 1.3rem;
      font-weight: 400;
    }
  }
}
.delivery-info-label {
  min-width: 150px;

  @include for-sm {
    min-width: 80px;
  }
}
</style>
