<template>
  <div>
    <ValidationObserver>
      <div class="mb-4">
        <PickUpInfo v-show="step == PICK_UP_STEP.PICK_UP_INFO" @next="next" />
        <CustomerInfo v-show="step == PICK_UP_STEP.CUSTOMER_INFO" @back="back" />
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { PICK_UP_STEP } from '@/common/enum/checkout';
import PickUpInfo from './steps/pickup-info';
import CustomerInfo from './steps/customer-info';
export default {
  components: {
    PickUpInfo,
    CustomerInfo,
  },
  data() {
    return {
      step: null,
    };
  },
  computed: {
    PICK_UP_STEP() {
      return PICK_UP_STEP;
    },
  },
  watch: {
    $route(to, from) {
      const query = to.query;
      this.step = Number(query.step);
    },
  },
  created() {
    const step = Number(this.$route.query.step);

    switch (step) {
      case PICK_UP_STEP.PICK_UP_INFO:
        this.step = PICK_UP_STEP.PICK_UP_INFO;
        // this.navigatePush('OrderCheckout', { ...this.$route.params }, { step: PICK_UP_STEP.PICK_UP_INFO });
        break;
      case PICK_UP_STEP.CUSTOMER_INFO:
        // this.step = PICK_UP_STEP.CUSTOMER_INFO;
        // this.navigatePush('OrderCheckout', { ...this.$route.params }, { step: PICK_UP_STEP.CUSTOMER_INFO });

        // Redirect back to step 1 as the form data in step 1 is stored in vuex and will be cleared on page refresh
        this.navigateReplace('OrderCheckout', { ...this.$route.params }, { step: PICK_UP_STEP.PICK_UP_INFO });
        break;
      default:
        this.step = PICK_UP_STEP.PICK_UP_INFO;
        this.navigateReplace('OrderCheckout', { ...this.$route.params }, { step: PICK_UP_STEP.PICK_UP_INFO });
        break;
    }
  },
  methods: {
    back() {
      this.step = PICK_UP_STEP.PICK_UP_INFO;
      this.navigatePush('OrderCheckout', { ...this.$route.params }, { step: PICK_UP_STEP.PICK_UP_INFO });
    },
    next() {
      this.step = PICK_UP_STEP.CUSTOMER_INFO;
      this.navigatePush('OrderCheckout', { ...this.$route.params }, { step: PICK_UP_STEP.CUSTOMER_INFO });
    },
  },
};
</script>
<style lang="scss" scoped>
.note {
  color: var(--primary-black-color);
  font-size: 1.3rem;
  font-weight: 500;
}
</style>
