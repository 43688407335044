<template>
  <div>
    <ValidationObserver>
      <div class="mb-4">
        <DeliveryInfo v-show="step == DELIVERY_STEP.DELIVERY_INFO" @next="next" />
        <CustomerInfo v-show="step == DELIVERY_STEP.CUSTOMER_INFO" @back="back" />
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { DELIVERY_STEP } from '@/common/enum/checkout';
import DeliveryInfo from './steps/delivery-info';
import CustomerInfo from './steps/customer-info';
export default {
  components: {
    DeliveryInfo,
    CustomerInfo,
  },
  data() {
    return {
      step: null,
    }
  },
  computed: {
    DELIVERY_STEP() {
      return DELIVERY_STEP;
    },
  },
  watch: {
    $route(to, from) {
      const query = to.query;
      this.step = Number(query.step);
    }
  },
  created() {
    const step = Number(this.$route.query.step);

    switch(step) {
      case DELIVERY_STEP.DELIVERY_INFO:
        this.step = DELIVERY_STEP.DELIVERY_INFO;
        // this.navigateReplace('OrderCheckout', { ...this.$route.params }, { step: DELIVERY_STEP.DELIVERY_INFO });
        break;
      case DELIVERY_STEP.CUSTOMER_INFO:
        // this.step = DELIVERY_STEP.CUSTOMER_INFO;
        // this.navigateReplace('OrderCheckout', { ...this.$route.params }, { step: DELIVERY_STEP.CUSTOMER_INFO });

        // Redirect back to step 1 as the form data in step 1 is stored in vuex and will be cleared on page refresh
        this.navigateReplace('OrderCheckout', { ...this.$route.params }, { step: DELIVERY_STEP.DELIVERY_INFO });
        break;
      default:
        this.step = DELIVERY_STEP.DELIVERY_INFO;
        this.navigateReplace('OrderCheckout', { ...this.$route.params }, { step: DELIVERY_STEP.DELIVERY_INFO });
        break;
    }
  },
  methods: {
    back() {
      this.step = DELIVERY_STEP.DELIVERY_INFO;
      this.navigatePush('OrderCheckout', { ...this.$route.params }, { step: DELIVERY_STEP.DELIVERY_INFO });
    },
    next() {
      this.step = DELIVERY_STEP.CUSTOMER_INFO;
      this.navigatePush('OrderCheckout', { ...this.$route.params }, { step: DELIVERY_STEP.CUSTOMER_INFO });
    },
  }
}
</script>
<style lang="scss" scoped>
.note {
  color: var(--primary-black-color);
  font-size: 1.3rem;
  font-weight: 500;
}
</style>