<template>
  <div>
    <ValidationObserver ref="observer">
      <div class="mb-4">
        <div class="note mb-3">Fill in your details</div>

        <div>
          <TextField
            ref="name"
            v-model="form.name"
            :rules="rules.name"
            :outlined="false"
            :hide-details="false"
            :single-line="false"
            :label="'Your Name'"
          />
        </div>
        <div>
          <TextField
            ref="mobile"
            v-model="form.mobile"
            :rules="rules.mobile"
            :outlined="false"
            :hide-details="false"
            :single-line="false"
            :label="'Phone Number'"
            :type="'number'"
          />
        </div>
        <div>
          <TextField
            ref="email"
            v-model="form.email"
            :rules="rules.email"
            :outlined="false"
            :hide-details="false"
            :single-line="false"
            :label="'Email'"
            :type="'email'"
          />
        </div>
        <div>
          <TextField
            v-model="form.notes"
            :rules="rules.notes"
            :outlined="false"
            :hide-details="false"
            :single-line="false"
            :label="$t('lbl.remarks')"
            :max-length="rules.notes.max"
            :counter="rules.notes.max"
          />
        </div>
      </div>

      <v-divider class="mt-4 mb-3"></v-divider>

      <!-- <v-expansion-panels v-model="orderSummaryPanel" class="order-summ mb-2" accordion>
        <v-expansion-panel>
          <v-expansion-panel-header class="px-3 py-2">
            <div class="d-flex justify-space-between align-center">
              <div class="font-weight-medium font-1dot1r">Order Summary</div>
              <div class="font-weight-medium font-red-common font-1dot2r">{{ `${$t('lbl.rm')}${priceFormat(totalAmt)}` }}</div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="mb-3"></v-divider>
            <Summary :cart-items="currentCartItems" :foc-cart-items="currentFocCartItems" :amt-info="amtInfo" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->

      <v-expansion-panels v-model="orderSummaryPanel" class="order-summ mb-2" accordion>
        <v-expansion-panel>
          <v-expansion-panel-header class="px-3 py-2">
            <div class="d-flex justify-space-between align-center">
              <div class="font-weight-medium font-1dot1r">Order Summary</div>
              <div v-if="orderSummaryPanel == null" class="font-weight-medium">
                <span v-if="amtInfo.amtBeforePromo != totalAmt" class="line-through font-1dot1r font-grey-dark-1 mr-2">{{
                  `${$t('lbl.rm')}${priceFormat(amtInfo.amtBeforePromo)}`
                }}</span>
                <span class="font-red-common font-1dot1r">{{ `${$t('lbl.rm')}${priceFormat(totalAmt)}` }}</span>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="mb-3"></v-divider>
            <Summary
              :cart-items="currentCartItems"
              :foc-cart-items="currentFocCartItems"
              :amt-info="amtInfo"
              :offer-obj="offerObj"
              :order-type="DELIVERY_CHANNEL.PICK_UP"
              :delivery-time="pickUpInfo.schedule"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-divider class="mt-4 mb-3"></v-divider>

      <div class="note mb-3">Payment Method</div>

      <div class="mb-3">
        <ValidationProvider v-slot="{ errors }" :name="$t('payment.lbl.paymentChannel')" :rules="rules.payChannel">
          <PaymentChannel class="mb-1" v-model="form.payMethod" :list="onlinePayMethodList" />
          <span v-if="!isUndefinedNullOrEmpty(errors)" class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <PaymentForm ref="paymentForm" :data="pgFormData" />

      <AppBtn class="btn-black" block :height="42" :loading="loading" @click="placeOrder">{{ 'Place Order' }}</AppBtn>

      <CommonModal
        v-model="quotationModalShow"
        :modal-size="'modal-sm'"
        :title="'Discount Info Updated'"
        :footer="false"
        :close-btn="true"
      >
        <template #body>
          <div class="pa-3">
            <div class="font-1dot1r font-weight-regular mt-2 mb-4">
              Discount info have been updated.
            </div>
            <div class="text-right">
              <AppBtn class="btn-orange" @click="quotationModalShow = false">{{ $t('act.ok') }}</AppBtn>
            </div>
          </div>
        </template>
      </CommonModal>

      <CommonModal
        v-model="notAvailableModalShow"
        :modal-size="'modal-sm'"
        :title="'Pickup Not Available'"
        :footer="false"
        :close-btn="true"
      >
        <template #body>
          <div class="pa-3">
            <div class="font-1dot1r font-weight-regular mt-2 mb-4">
              Sorry, we are unable to serve your pickup location temporarily. Please select another location.
            </div>
            <div class="text-right">
              <AppBtn
                class="btn-orange"
                @click="
                  notAvailableModalShow = false;
                  $emit('back');
                "
                >{{ $t('act.ok') }}</AppBtn
              >
            </div>
          </div>
        </template>
      </CommonModal>
    </ValidationObserver>
  </div>
</template>
<script>
import PaymentChannel from '../../../payment/channel';
import PaymentForm from '../../../payment/form';
import CommonModal from '@/components/Modal';
import { DELIVERY_CHANNEL, PAY_OPTION } from '@/common/enum/checkout';
import { orderApi } from '@/api/order';
import { quotationApi } from '@/api/offer';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import Summary from '@/views/Order/summary';

export default {
  components: {
    CommonModal,
    PaymentChannel,
    PaymentForm,
    Summary,
  },
  data() {
    return {
      form: {
        mobile: '',
        name: '',
        email: '',
        payMethod: null,
        notes: '',
      },
      loading: false,

      pgFormData: {
        actionUrl: '',
        merchantId: '',
        amount: '',
        orderId: '',
        billName: '',
        billEmail: '',
        billMobile: '',
        billDesc: '',
        country: '',
        vcode: '',
        currency: '',
        channel: '',
        returnurl: '',
        callbackurl: '',
      },

      payOption: PAY_OPTION.PAY_ONLINE,
      storeName: '',
      orderSummaryPanel: null,
      quotationModalShow: false,
      notAvailableModalShow: false,

      rules: {
        mobile: { required: true, min: 9, regex: /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/ },
        name: { required: true, min: 2 },
        email: { required: true, email: true },
        payChannel: { required: true },
        notes: { required: false, max: 50 },
      },
    };
  },
  computed: {
    ...mapState({
      storeDdlList: state => state.store.storeDdlList,
      onlinePayMethodList: state => state.lookup.onlinePayMethodList,
      pickUpInfo: state => state.checkout.pickup.form.pickupInfo,
    }),
    ...mapGetters([
      'getCartItems',
      'getFocCartItems',
      'getPlacedCartItems',
      'getPlacedFocCartItems',
      'getUserDisplayName',
      'getMobileNo',
      'getEmail',
    ]),
    PAY_OPTION() {
      return PAY_OPTION;
    },
    DELIVERY_CHANNEL() {
      return DELIVERY_CHANNEL;
    },
    currentCartItems() {
      return !this.isUndefinedNullOrEmpty(this.getPlacedCartItems) ? this.getPlacedCartItems : this.getCartItems;
    },
    currentFocCartItems() {
      return !this.isUndefinedNullOrEmpty(this.getPlacedFocCartItems) ? this.getPlacedFocCartItems : this.getFocCartItems;
    },
    totalAmtBeforePromo() {
      if (this.isUndefinedNullOrEmpty(this.currentCartItems)) return 0;
      let total = 0;
      for (let item of this.currentCartItems) {
        total = total + item.qty * item.price;
      }
      return total;
    },
    totalAmt() {
      if (!this.isUndefinedNullOrEmpty(this.offerObj)) return this.totalAmtBeforePromo - this.offerObj.discountAmt;
      return this.totalAmtBeforePromo;
    },
    amtInfo() {
      return {
        totalAmt: this.totalAmt,
        amtBeforePromo: this.totalAmtBeforePromo,
      };
    },
    // offer() {
    //   return {
    //     ...this.pickUpInfo.rate,
    //     discAmt: (this.totalAmtBeforePromo * this.pickUpInfo.rate?.disc) / 100,
    //   };
    // },
    offerObj() {
      if (this.isUndefinedNullOrEmpty(this.pickUpInfo.quotation)) return {};
      return {
        discountedAmt: this.pickUpInfo.quotation.amt,
        discountRate: this.pickUpInfo.quotation.rate.disc, // 0.2 . Not in percentage.
        discountAmt: this.pickUpInfo.quotation.rate.discAmt,
      };
    },
    // promoObj() {
    // if (this.isUndefinedNullOrEmpty(this.getPromoList)) return {};
    // return this.getPromoList[this.selectedPromo];
    // },
  },
  created() {
    if (this.isUndefinedNullOrEmpty(this.onlinePayMethodList)) this.fetchOnlinePayMethodList();

    if (!this.isUndefinedNullOrEmpty(this.getUserDisplayName)) this.form.name = this.getUserDisplayName;
    if (!this.isUndefinedNullOrEmpty(this.getMobileNo)) this.form.mobile = this.getMobileNo;
    if (!this.isUndefinedNullOrEmpty(this.getEmail)) this.form.email = this.getEmail;
  },
  methods: {
    ...mapMutations([
      'SET_STORE_ID',
      'SET_PRE_SELECTED_CHANNEL_FLAG',
      'SET_STORE_NAME',
      'SET_USER_DISPLAY_NAME',
      'SET_USER_MOBILE_NO',
      'SET_EMAIL',
      'SET_ORDER_USER_ID',
      'SET_PICKUP_INFO_FORM',
    ]),
    ...mapActions(['clearCart', 'setOrderHistory', 'fetchOnlinePayMethodList', 'setPlacedCart', 'setPlacedFocCart']),
    // scrollToBottom() {
    //   window.scrollTo(0,document.body.scrollHeight);
    // },
    async placeOrder() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      if (this.isUndefinedNullOrEmpty(this.currentCartItems)) {
        this.showError('Your cart is empty');
        return;
      }

      let data = {
        storeId: 0, // Pickup uses locId. Pass storeId: 0 here to bypass API check.
        locId: this.pickUpInfo.locId,
        postcode: this.pickUpInfo.postcode,
        doDate: this.pickUpInfo.schedule,
        channel: DELIVERY_CHANNEL.PICK_UP,
        amt: Number(this.priceFormat(this.totalAmt)),
        discAmt: this.offerObj?.discountAmt,
        mobile: this.form.mobile.trim(),
        email: this.form.email.trim(),
        name: this.form.name.trim(),
        items: [...this.processItems(this.currentCartItems), ...this.processItems(this.currentFocCartItems)],
        payMethod: this.form.payMethod,
        notes: this.form.notes,
        quotationSignature: this.pickUpInfo.quotationSignature,
      };

      this.loading = true;

      return orderApi
        .place(data)
        .then(async res => {
          if (res.ret == '0') {
            const resData = res.data;

            // this.SET_STORE_NAME(this.storeName);
            this.SET_STORE_ID(null); // Clear store id so that it won't store forever
            this.SET_PRE_SELECTED_CHANNEL_FLAG(1); // set flag = 1 so that the modal won't pop up when user back to menu all
            this.SET_USER_DISPLAY_NAME(data.name);
            this.SET_USER_MOBILE_NO(data.mobile);
            this.SET_EMAIL(data.email);
            this.SET_ORDER_USER_ID(resData.userId);

            // this.setOrderHistory({
            //   amt: resData.amt,
            //   orderId: resData.orderId,
            //   seq: resData.seq,
            //   curSeq: resData.curSeq,
            //   userId: resData.userId,
            //   time: moment.parseZone(new Date()).format('h:mma'),
            //   data: [...this.currentCartItems, ...this.currentFocCartItems],
            //   cancelled: 0,
            //   status: resData.status,
            //   dChannel: DELIVERY_CHANNEL.PICK_UP,
            //   storeName: '',
            //   locName: this.pickUpInfo.locName,
            //   scheduleType: this.pickUpInfo.scheduleType,
            //   schedule: this.pickUpInfo.schedule,
            // });
            // await this.clearCart();
            this.setPlacedCart(this.currentCartItems);
            this.setPlacedFocCart(this.currentFocCartItems);

            this.postToPg(resData);
          } else {
            if (res.ret == 'InvalidSignature') {
              const quotationRes = await this.getPickupQuotation(
                this.totalAmtBeforePromo,
                this.pickUpInfo.quotation.bizDay,
                this.pickUpInfo.quotation.locId,
                this.pickUpInfo.quotation.slot
              );

              if (!quotationRes.signature) {
                this.notAvailableModalShow = true;
              } else {
                this.SET_PICKUP_INFO_FORM({
                  ...this.pickUpInfo,
                  quotation: quotationRes.quotation,
                  quotationSignature: quotationRes.signature,
                });

                this.quotationModalShow = true;
              }
            } else if (res.ret == 'InvalidRequest') {
              if (res.data?.cause[0]?.err) {
                this.showError(res.data.cause[0].err);
              } else {
                this.showError(res.msg);
              }
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    postToPg(data) {
      this.pgFormData.actionUrl = data.tx.url;
      this.pgFormData.merchantId = data.tx.merchantId;
      this.pgFormData.amount = this.priceFormat(data.amt); // Need to pass .00 to Razer . Eg. 1.00, 2.00
      this.pgFormData.orderId = data.tx.orderNo;
      this.pgFormData.billName = this.form.name;
      this.pgFormData.billEmail = this.form.email;
      this.pgFormData.billMobile = this.form.mobile;
      this.pgFormData.country = data.tx.country;
      this.pgFormData.vcode = data.tx.vcode;
      this.pgFormData.currency = data.tx.curr;
      this.pgFormData.channel = data.tx.channel;

      this.$nextTick(() => {
        this.loading = true;
        this.$refs['paymentForm'].postToPg();
        this.loading = false;
      });
    },
    processItems(data) {
      return data.map(item => {
        return {
          itemId: item.menuItemId,
          qty: item.qty,
          items: !this.isUndefinedNullOrEmpty(item.items)
            ? item.items.map(innerItem => {
                return {
                  itemId: innerItem.itemId,
                  qty: innerItem.qty,
                };
              })
            : null,
        };
      });
    },
    getPickupQuotation(amt, bizDay, locId, slot) {
      const data = {
        amt,
        bizDay,
        locId,
        slot,
      };
      return quotationApi.getPickupQuotation(data).then(res => {
        return res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.note {
  color: var(--primary-black-color);
  font-size: 1.15rem;
  font-weight: 500;
}
.pay-option-wrap {
  .v-radio::v-deep {
    .v-icon {
      font-size: 30px;
    }
    .v-label {
      font-size: 1.3rem;
      font-weight: 400;
    }
  }
}
.v-expansion-panel {
  .v-expansion-panel-header {
    &--active {
      min-height: 45px;
    }
  }
  .v-expansion-panel-content::v-deep {
    .v-expansion-panel-content__wrap {
      padding: 0 12px 12px;
    }
  }
}
</style>
