import { orderDeliveryApi } from '@/api/order';
import { quotationApi } from '@/api/offer';

export default {
  data() {
    return {};
  },
  methods: {
    getDeliveryQuotation(payload) {
      const data = {
        amt: payload.amt,
        bizDay: payload.bizDay,
        locId: payload.locId,
        slot: payload.slot,
        delivery: {
          points: [
            {
              address: payload.address,
              postcode: payload.postcode,
              // country: payload.country,
              contact: {
                name: payload.name,
                phone: payload.phone,
              },
            },
          ],
        },
      };

      return quotationApi.getDeliveryQuotation(data).then(res => {
        return res.data;
      });
    },
    // getDeliveryQuotation(payload) {
    //   const data = {
    //     userId: payload.userId,
    //     amt: payload.amt,
    //     scheduleAt: payload.scheduleAt,
    //     points: [
    //       {
    //         address: payload.address,
    //         postcode: payload.postcode,
    //         country: payload.country,
    //         contact: {
    //           name: payload.name,
    //           phone: payload.phone,
    //         }
    //       }
    //     ]
    //   }

    //   return orderDeliveryApi.quotation(data).then(res => {
    //     return res.data;
    //   })
    // },
  },
};
